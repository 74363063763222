    <template>
    <div class="aboutUs">
        <div class="aboutUs_img_f">
        <div class="aboutUs_img">
            <img src="@/assets/image/aboutUs1.png">
        </div>
        </div>
        <div class="introduce">
            <div class="header">因为专注，所以专业</div>
            <div class="content">
                    <!-- pc端 -->
                    <p class="pc_p">致力于为中国企业提供高品质的互联网解决方案服务，服务涵盖：APP软件开发、网站建设、电商平台搭建、大数据平台搭建、以及行业解决方案服务</p>
                    <p class="pc_p">我们不断学习、成长，不断积累项目操作经验，努力成为中国设计界不可或缺的力量。</p>
                    <!-- 移动端 -->
                    <p class="mobile_p">
                        致力于为中国企业提供高品质的互联网解决方案服务，服务涵盖：APP软件开发、网站建设、电商平台搭建、大数据平台搭建、以及行业解决方案服务
                        我们不断学习、成长，不断积累项目操作经验，努力成为中国设计界不可或缺的力量。
                    </p>
            </div>
            <ul>
                <li v-for="(item,index) in introduceList" :key="index">
                    <img :src="item.img" alt="">
                    <p>{{item.title}}</p>
                </li>
            </ul>
        </div>
        <div class="choose" >
            <div class="choose_t"  v-show="chooseShow">
                <div class="h2">为什么选择我们</div>
                <span class="line"></span>
                <span class="text">我们以高品质设计创造商业价值，以创新理念提升品牌影响力</span>
                <span class="line"></span>
            </div>
            <div class="choose_b"  v-show="chooseShow">
                <div class="choose_b_l">
                    <img src="@/assets/image/aboutUs4.png">
                </div>
                <div class="choose_b_r">
                    <p>广州峰盛信息科技有限公司擅长创意设计、互联网应用与各类业务系统深度整合，以及大型项目的执行与管理，致力于为企业深度制定跨平台、全链条的互联网解决方案。</p>
                    <p>我们提供PC端网站建设（品牌展示型、官方门户型、营销商务型、电子商务型、信息门户型）、移动端应用（手机站、自适应网站、APP开发）、微信定制开发（小程序、订阅号、服务号、企业微信）等一系列互联网应用服务。</p>
                </div>
            </div>
        </div>
        <div class="culture">
            <div class="culture_h2" v-show="cultureShow">企业文化</div>
            <div class="culture_img">
                <div class="culture_img_l" v-show="cultureShow">
                    <div class="culture_img_l_l">
                        <img src="@/assets/image/aboutUs3.png">
                    </div>
                    <div class="culture_img_l_r">
                        <div class="culture_img_l_r_top">
                            <div class="h2">务实</div>
                            <div class="text">我们崇尚简单实在的工作关系，坦诚和平等是我们的原则</div>
                        </div>
                        <div class="culture_img_l_r_top">
                            <div class="h2">卓越</div>
                            <div class="text">追求质量和稳定</div>
                        </div>
                    </div>
                </div>
                <div class="culture_img_r" v-show="cultureShow">
                    <div class="culture_img_r_top">
                        <img src="@/assets/image/aboutUs2.png">
                    </div>
                    <div class="culture_img_r_bottom">
                        <div class="culture_img_r_bottom_l">
                            <div class="h2">创新</div>
                            <div class="text">独立思考能为我们带来新的创意和解决办法</div>
                        </div>
                        <div class="culture_img_r_bottom_l">
                            <div class="h2">协作</div>
                            <div class="text">我们深信，没有团队的支持我们不能走的更远</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 移动端两个模块 -->
        <div class="mobileChoose">
            <div class="header">为什么选中我们</div>
            <div class="text1">我们以高品质设计创造商业价值，以创新理念提升品牌影响力</div>
            <img src="@/assets/image/aboutUs4.png">
            <div class="text2">
                   广州峰盛信息科技有限公司擅长创意设计、互联网应用与各类业务系统深度整合，以及大型项目的执行与管理，致力于为企业深度定制跨平台、全链条的互联网解决方案。<br />
                    我们提供PC端网站建设（品牌展示型、官方门户型、营销商务型、电子商务型、信息门户型）、移动端应用（手机站、自适应网站、APP开发）、微信定制开发（小程序、订阅号、服务号、企业微信）等一系列互联网应用服务。
            </div>
        </div>
        <div class="mobileCulture">
            <div class="header">企业文化</div>
            <div class="mobileCulture_text">
                <ul>
                    <li>
                        <div class="h2">务实</div>
                        <div class="text">我们崇尚简单实在的工作关系，坦诚和平等是我们的原则</div>
                    </li>
                    <li>
                        <div class="h2">卓越</div>
                        <div class="text">追求质量和稳定</div>
                    </li>
                    <li>
                        <div class="h2">协作</div>
                        <div class="text">我们深信，没有团队的支持我们不能走的更远</div>
                    </li>
                    <li>
                        <div class="h2">创新</div>
                        <div class="text">独立思考能为我们带来新的创意和解决办法</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    </template>

    <script>

    export default {
        data(){
            return{
                introduceList:[
                    {
                        img: require("@/assets/image/scheme.png"),
                        title:'技术实现'
                    },
                    {
                        img: require("@/assets/image/realize.png"),
                        title:'解决方案'
                    }
                ],
                chooseShow:false,
                cultureShow:false
            }
        },
        mounted(){
            window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
        },
        methods:{
            // 滚动监听 · 动画播放
            handleScroll(){ 
                    // 页面滚动距顶部距离
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || 
                        document.body.scrollTop
                //动画播放设置
                let choose = document.getElementsByClassName('choose')
                let culture = document.getElementsByClassName('culture')
                let culture_h2 = culture[0].getElementsByClassName('culture_h2')
                let culture_img_l = culture[0].getElementsByClassName('culture_img_l')
                let culture_img_r = culture[0].getElementsByClassName('culture_img_r')
                // let serve_p = document.getElementsByName('serve_p')
                // let serveUl = serve[0].getElementsByTagName('ul')
                // let echart = document.getElementsByClassName('echart')
                // let echart_text = echart[0].getElementsByClassName('echart_text')
                // let echart_c = echart[0].getElementsByClassName('echart_c')
                if(scrollTop > (choose[0].getBoundingClientRect().top + scrollTop) - choose[0].offsetHeight * 2){
                    console.log(1);
                    this.chooseShow = true
                    choose[0].classList.add('animated','fadeIn')
                }
                if(scrollTop > (culture[0].getBoundingClientRect().top + scrollTop) - culture[0].offsetHeight){
                    this.cultureShow = true
                    culture_h2[0].classList.add('animated','fadeIn')
                    culture_img_l[0].classList.add('animated','fadeInLeft')
                    culture_img_r[0].classList.add('animated','fadeInRight')
                }
                // if(scrollTop > (serve[0].getBoundingClientRect().top + scrollTop) - serve[0].offsetHeight){
                //     this.serveShow = true
                //     serve_p[0].classList.add('animated','fadeIn')
                //     serveUl[0].classList.add('animated','fadeIn')
                // }
                // if(scrollTop > (echart[0].getBoundingClientRect().top + scrollTop) - echart[0].offsetHeight / 2 ){
                //     this.echartShow = true
                //     echart_text[0].classList.add('animated','fadeIn')
                //     echart_c[0].classList.add('animated','zoomIn')
                // }
            } 
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll, true);
        },
    }
    </script>

    <style lang="less" scoped>
        .aboutUs {
            width: 100%;
            font-family: Source Han Sans CN;
            .aboutUs_img_f {
                height: 420px;
                overflow: hidden;
                .aboutUs_img {
                    height: 420px;
                    transition: all 0.6s;
                    &:hover {
                        transform: scale(1.2);
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .introduce{
                height: 524px;
                .header{
                    font-size: 36px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #333333;
                    margin: 80px 0 30px 0;
                }
                .content{
                    width: 882px;
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 300;
                    color: #666666;
                    margin: 0 auto;
                    .mobile_p{
                            display: none;
                    }
                }
                ul{
                    display: flex;
                    margin: 0 auto;
                    justify-content: center;
                    margin-top: 55px;
                    li:nth-child(1){
                        img{
                            margin: 27px 0 14px 0;
                        }
                    }
                    li:nth-child(2){
                        margin: 0;
                        img{
                            margin: 32px 0 15px 0;
                        }
                    }   
                    li{
                        width: 195px;
                        height: 175px;
                        border-radius: 10px;
                        background: url('~@/assets/image/rectangle1.png') no-repeat;
                        margin-right: 52px;
                        p{
                            font-size: 22px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #666666;
                        }
                    }
                }
            }
            .choose {
                position:relative;
                height: 662px;
                background-color: #f8f8f8;
                .choose_t {
                    .h2 {
                        font-size: 36px;
                        font-weight: bold;
                        color: #333333;
                        // line-height: 81px;
                        padding-top: 60px;
                    }
                    .text {
                        font-size: 18px;
                        color: #666666;
                        margin: 0 10px;
                        line-height: 60px;
                    }
                    .line {
                        display: inline-block;
                        width: 140px;
                        height: 1px;
                        background: #e7e7e7;
                        vertical-align: middle;
                    }
                }
                .choose_b {
                    display: flex;
                    position: absolute;
                    top: 60%;
                    left:50%;
                    transform: translate(-50%,-50%);
                    .choose_b_l {
                        width: 377px;
                        height: 287px;
                        margin-top: 85px;
                    }
                    .choose_b_r {
                        width: 513px;
                        height: 212px;
                        text-align: left;
                        font-size: 16px;
                        color: #666;
                        margin-top: 120px;
                        margin-left: 167px;
                        p {
                            &:nth-child(1) {
                                margin-bottom: 40px;
                            }
                        }
                    }
                }
            }
            .culture {
                height: 940px;
                .culture_h2 {
                    font-size: 36px;
                    font-weight: bold;
                    color: #333333;
                    line-height: 212px;
                }
                .culture_img {
                    display: flex;
                    height: 606px;
                        
                    .culture_img_l {
                        display: flex;
                        flex: 1;
                        background-color: red;
                        overflow: hidden;
                        .culture_img_l_l {
                            flex:1;
                            transition: all 0.6s;
                            &:hover {
                                transform: scale(1.4);
                            }
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .culture_img_l_r {
                            flex: 1;
                            
                            .culture_img_l_r_top {
                                flex:1;
                                position:relative;
                                height: 303px;
                                background-color: #f8f8f8;
                                .h2 {
                                    position: absolute;
                                    top: 60px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: 50%;
                                    font-size: 34px;
                                    font-weight: bold;
                                    color: #333333;
                                    line-height: 80px;
                                    border-bottom: 1px solid #ccc;
                                }
                                .text {
                                    position: absolute;
                                    top: 160px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    font-size: 18px;
                                    color: #666666;
                                    line-height: 34px;
                                }
                                &:nth-child(2) {
                                background-color: #1f96fb;
                                .h2, .text {
                                    color: #fff;
                                }
                            }
                            }
                            // .culture_img_l_r_bottom {
                            //     height: 303px;
                            //     background-color: #1f96fb;
                            // }
                        }
                    }
                    .culture_img_r {
                        flex: 1;
                        // background-color: blue;
                            overflow: hidden;
                        .culture_img_r_top {
                            height: 303px;
                            transition: all 0.6s;
                            &:hover {
                                transform: scale(1.2);
                            }
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .culture_img_r_bottom {
                            display: flex;
                            height: 303px;
                            .culture_img_r_bottom_l {
                                flex:1;
                                background-color: #f8f8f8;
                                position:relative;
                                height: 303px;
                                background-color: #f8f8f8;
                                .h2 {
                                    position: absolute;
                                    top: 60px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: 50%;
                                    font-size: 34px;
                                    font-weight: bold;
                                    color: #333333;
                                    line-height: 80px;
                                    border-bottom: 1px solid #ccc;
                                }
                                .text {
                                    position: absolute;
                                    top: 160px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    font-size: 18px;
                                    color: #666666;
                                    line-height: 34px;
                                }
                                &:nth-child(2) {
                                background-color: #1f96fb;
                                .h2, .text {
                                    color: #fff;
                                }
                            }
                            }
                            // .culture_img_r_bottom_r {
                            //     flex:1;
                            //     background-color: #39a1fb;
                            // }
                        }
                    }
                }
            }
            .fadeIn{
                // animation-duration: 5s;         //动画持续时间
                animation-delay: 1s !important;              //动画延迟时间
                // animation-iteration-count: 2 !important;
            }
            .zoomIn,.fadeInRight,.fadeInLeft,.fadeInUp{
                // animation-duration: 5s;         //动画持续时间
                animation-delay: 1.5s !important;              //动画延迟时间
                // animation-iteration-count: 2 !important;
            }
        }

        @media screen and (max-width: 1024px) {
            .aboutUs {
                .introduce{
                    height: 282px;
                    .header{
                        font-size: 19px;
                        margin: 29px 0 14px 0;
                    }
                    .content{
                        width: 341px;
                        font-size: 11px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 17px;
                        .pc_p{
                            display: none;
                        }
                        .mobile_p{
                            display: block;
                        }
                    }
                    ul{
                        margin-top: 20px;
                        li:nth-child(1){
                            img{
                                width: 30px;
                                height: 37px;
                                margin: 15px 0 7px 0;
                            }
                        }
                        li:nth-child(2){
                            margin: 0;
                            img{
                                width: 32px;
                                height: 32px;
                                margin: 20px 0 7px 0;
                            }
                        }
                        li{
                            width: 125px;
                            height: 105px;
                            background-size: 125px 105px;
                            margin-right: 15px;
                            p{
                                font-size: 14px;
                            }
                        }
                    }
                }
                .choose {
                    display: none;
                }
                .culture {
                    display: none;
                }
                .aboutUs_img_f {
                    height: 110px !important;
                    // background-color: red;
                    .aboutUs_img {
                        height: 110px !important;
                        // img {
                        //     // width: 100% !important;
                        //     // height: 100% !important;
                        // }
                    }
                }
                .mobileChoose {
                    background-color: #f8f8f8;
                    .header {
                        font-size: 19px;
                        font-weight: 700;
                        padding-top: 30px;
                    }
                    .text1 {
                        font-size: 10px;
                        color: #666;
                        margin-top: 5px;
                    }
                    img {
                        width: 50%;
                        margin: 24px 0;
                        // height: 143px;
                    }
                    .text2 {
                        line-height: 20px;
                        font-size: 12px;
                        color: #666;
                        padding: 0 20px;
                        text-align: left;
                        padding-bottom: 25px;
                    }
                }
                .mobileCulture {
                    .header {
                        font-size: 19px;
                        font-weight: 700;
                        margin: 29px 0 20px;
                    }
                    .mobileCulture_text {
                        ul {
                            display: flex;
                            flex-wrap: wrap;
                            li {
                                position: relative;
                                width: 50%;
                                height: 136.5px;
                                // background-color: red;
                                // border: 1px solid #000;
                                .h2 {
                                    position: absolute;
                                    top: 25px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: 143px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    text-align: center;
                                    border-bottom: 1px solid #ccc;
                                }
                                .text {
                                    width: 150px;
                                    font-size: 12px;
                                    color: #666;
                                    position: absolute;
                                    top: 65px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                                &:nth-child(1) {
                                    background-color: #f8f8f8;
                                    
                                }
                                &:nth-child(2) {
                                    background-color: #1f96fb;
                                    .h2 {
                                        color: #fff;
                                        border-bottom: 1px solid #fff;
                                    }
                                    .text {
                                        color: #fff;
                                    }
                                }
                                &:nth-child(3) {
                                    background-color: #1f96fb;
                                    .h2 {
                                        color: #fff;
                                        border-bottom: 1px solid #fff;
                                    }
                                    .text {
                                        color: #fff;
                                    }
                                }
                                &:nth-child(4) {
                                    background-color: #f8f8f8;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (min-width: 1025px) {
            .aboutUs {
                margin-top: 90px;
            }
            .mobileChoose {
                display: none;
            }
            .mobileCulture {
                display: none;
            }
        }
    </style>